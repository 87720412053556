import React from 'react';
import logo from '../../assets/Santa fé (1) 2 (1).png';

function FooterComponent() {
  return (
    <footer style={{ backgroundColor: '#FFF', color: 'black', width: '100%', paddingTop: "4%" }}>
      <div className="container">
        <div className="row">
          {/* Coluna 1 */}
          <div className="col-md-4">
          
            <div className="mb-3" style={{ fontSize: '20px', fontWeight: 'bold' }}>Entre em contato agora mesmo</div>
            <button className="btn btn-light d-block mb-2">+55 92 8130-6272</button>

          </div>
          {/* Coluna 2 */}
          <div className="col-md-4 text-center">
            <img src={logo} alt="Imagem" style={{ width: '400px', height: '300px' }} />
          </div>
          {/* Coluna 3 */}
          <div className="col-md-4">

          </div>
        </div>
        {/* Linha fina e texto de direitos autorais */}
        <hr style={{ borderTop: '1px solid white', margin: '20px 0' }} />
        <p style={{ fontStyle: 'italic' }}>© 2023 Jungle Tech Innovation. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default FooterComponent;







