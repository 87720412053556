import React from 'react';
import { Carousel } from 'react-bootstrap';
import './styles.css';
import zap from '../../assets/whatsapp.png';

function ModalContent({ show, handleCloseModal, selectedCar }) {
  // Objeto contendo todas as informações do carro, incluindo a descrição
  const carDescription = {
    name: selectedCar?.name,
    year: selectedCar?.year,
    km: selectedCar?.km,
    color: selectedCar?.color,
    transmission: selectedCar?.transmission,
    doors: selectedCar?.doors,
    price: selectedCar?.price,
    description: selectedCar?.description
  };

  // Formatação da mensagem com os dados do carro, incluindo a descrição
  const formattedMessage = `
    Olá!

    Estou interessado no seguinte carro:

    - Nome: ${carDescription.name}
    - Ano: ${carDescription.year}
    - Quilometragem: ${carDescription.km} km
    - Cor: ${carDescription.color}
    - Câmbio: ${carDescription.transmission}
    - Número de portas: ${carDescription.doors}
    - Preço: ${carDescription.price}

    Descrição: ${carDescription.description}

    Poderia me fornecer mais informações?
  `;

  const openWhatsApp = () => {
    const phoneNumber = '5592981036965'; // Inclua o código do país e o número de telefone
    const message = encodeURIComponent(formattedMessage);
    window.open(`https://wa.me/${phoneNumber}?text=${message}`, '_blank');
  };

  return (
    <div className={`modal ${show ? 'd-block' : ''}`} tabIndex="-1" role="dialog" style={{ display: show ? 'block' : 'none' }}>
      <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div className="modal-content">
          <div className="modal-header" style={{ display: "flex", justifyContent: "space-between" }}>
            <h2 className="modal-title">{selectedCar?.name}</h2>
            <button style={{ backgroundColor: "black", color: "white" }} type="button" className="close" onClick={handleCloseModal}>
              <span>X</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              {/* Lado Esquerdo */}
              <div className="col-md-6">
                <Carousel style={{ height: '250px', width: '100%' }}>
                  {selectedCar?.images.map((image, index) => (
                    <Carousel.Item key={index} style={{ height: '300px', objectFit: 'contain', marginTop: `30px` }}>
                      <img
                        className="d-block w-100"
                        src={image}
                        alt={`Slide ${index + 1}`}
                        
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
                <div className="d-flex mb-3 cars-info-container" style={{ marginTop: '100px', }}>
                  <div className="me-3 line">Nome: <br /> {selectedCar?.name}</div>
                  <div className="me-3 line">Ano: {selectedCar?.year}</div>
                  <div className="me-3 line">KM: {selectedCar?.km}</div>
                  <div className="me-3 line">Cor: {selectedCar?.color}</div>
                  <div className="me-3 line">Câmbio: {selectedCar?.transmission}</div>
                  <div>Portas: {selectedCar?.doors}</div>
                </div>
              </div>
              {/* Lado Direito */}
              <div className="col-md-6">
                <div className="d-flex flex-column justify-content-center align-items-center mb-3">
                  <span >Valor à vista</span>
                  <button className="btn btn-dark text-white mb-2"  style={{ width: "300px", fontSize: '30px' }}>{selectedCar?.price}</button>


                  <h5>Entre em contato agora mesmo:</h5>
                  <br />
                  <button className="btn" style={{ width: "300px", border: "1px solid black" }} onClick={openWhatsApp}>
                    <img src={zap} alt="" srcset=""  style={{width: '24px'}}/>  (92) 98103 6965
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>Fechar</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalContent;