import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";

import { HomeView } from "./views/main/index";

export const Router = () => {
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route element={<HomeView />} path="" exact />
                </Routes>
            </BrowserRouter>
        </div>
    );
};