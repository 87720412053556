import React from 'react';
import carro from '../../assets/pngwing.com.png';

function ConsorcioComponent() {
  // Função para enviar mensagem para o número específico via WhatsApp
  const sendMessage = () => {
    const phoneNumber = '5592981036965'; // Número de telefone com código de país
    const message = encodeURIComponent("Olá! Estou interessado em saber mais sobre as ofertas de consórcio e a compra de consórcios. Poderia me fornecer mais informações?"); // Mensagem pré-formatada
    window.open(`https://wa.me/${phoneNumber}?text=${message}`, '_blank'); // Abre o WhatsApp em uma nova aba com a mensagem pré-formatada
  };

  return (
    <div className="row m-0" style={{ backgroundColor: 'black', color: 'white', marginTop: "1000px" }}>
      {/* Lado Esquerdo */}
      <div className="col-md-6 p-5">
        <div className="mb-4" style={{ fontSize: '24px' }}>Realize o seu sonho</div>
        <div className="mb-4" style={{ fontSize: '72px' }}>CONHEÇA NOSSAS OFERTAS DE CONSÓRCIO</div>
        <div className="mb-4" style={{ fontSize: '24px' }}>REALIZAMOS TAMBÉM A COMPRA DO SEU CONSÓRCIO</div>
        <button className="btn btn-light text-dark" onClick={sendMessage}>ENTRE EM CONTATO PARA MAIS INFORMAÇÕES</button>
      </div>
      {/* Lado Direito */}
      <div className="col-md-6 p-5 d-flex align-items-center justify-content-center" style={{ marginBottom: '-50px' }}>
        <img src={carro} alt="Imagem do Carro" style={{ maxWidth: '100%' }} />
      </div>
    </div>
  );
}

export default ConsorcioComponent;