import React from 'react';
import { Carousel } from 'react-bootstrap';
import carro from '../../assets/carro1.jpg';
import './styles.css'; 
import montana from '../../assets/montana.jpg';

import polo from '../../assets/strada.jpg';

function MyCarousel() {
    return (
        <div className="carousel-container" style={{ height: '90vh' }}>
            <Carousel className="carousel" style={{ height: '100%' }}>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={carro}
                        alt="Primeiro Slide"
                        style={{ height: '90vh', objectFit: 'cover' }}
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={montana}
                        alt="Segundo Slide"
                        style={{ height: '90vh', objectFit: 'cover' }}
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={polo}
                        alt="Terceiro Slide"
                        style={{ height: '90vh', objectFit: 'cover' }}
                    />
                </Carousel.Item>
                {/* Adicione mais imagens conforme necessário */}
            </Carousel>
            <div className="center-text">
                OS MELHORES CARROS <br/> COM OS MELHORES PREÇOS
            </div>
        </div>
    );
}

export default MyCarousel;
