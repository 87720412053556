import React, { useState } from 'react';
import './styles.css';
import ModalContent from '../individualCar/Index'; // Importe o componente do modal
import montanaPremierBranca from '../../assets/montanaPremier.jpg'
import montana from '../../assets/montana.jpg'
import montanaBranco from '../../assets/montana_branco.jpg'

import montana1 from '../../assets/premier_1.jpg'
import montana2 from '../../assets/premier_2.jpg'
import montana3 from '../../assets/premier_3.jpg'
import montana4 from '../../assets/premier_4.jpg'


import polotrack from '../../assets/polotrack.jpg'
import polo1 from '../../assets/polo1.jpg'
import polo2 from '../../assets/polo2.jpg'



import onyx from '../../assets/onyx1.jpg'
import onyx2 from '../../assets/onyx2.jpg'
import onyx3 from '../../assets/onyx3.jpg'
import onyx4 from '../../assets/onyx4.jpg'

import strada from '../../assets/strada.jpg'
import polo from '../../assets/polotrack.jpg'

function CardGrid() {
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [selectedCar, setSelectedCar] = useState(null); // Estado para armazenar os dados do carro selecionado

  // Array de objetos contendo os dados mock de cada carro
  const carsData = [
    {
      name: 'Montana',
      year: '2023/2024',
      km: '900',
      color: 'Branco',
      transmission: 'Automático',
      doors: 4,
      price: 'A negociar',
      images: [montanaPremierBranca, montanaBranco],
      description: "Montana premier Zero, Oportunidade! Veículo já financiado"
    },
    {
      name: 'Strada',
      year: '2023/2024',
      km: '0',
      color: 'Vermelho',
      transmission: 'Automático',
      doors: 4,
      price: 'A negociar',
      images: [strada],
      description: "Fiat Strada Volcano Zero KM entrego emplacada"
    },
    {
      name: 'Montana',
      year: '2021/2022',
      km: '4329',
      color: 'Cinza',
      transmission: 'Automático',
      doors: 4,
      price: 'R$ 120,000',
      images: [montana, montana1, montana2, montana3, montana4],
      description: "Montana premier Ano 2024, zero, entrego emplacada"
      // Array de imagens
    },
    {
      name: 'Polo',
      year: '2024',
      km: '0',
      color: 'Cinza',
      transmission: 'Automático',
      doors: 4,
      price: 'A negociar',
      images: [ polotrack ,polo1, polo2],
      description: "Polo track ano 2024 Zero KM emplacada"

    },
    {
      name: 'Onyx',
      year: '2018',
      km: '10000',
      color: 'Branco',
      transmission: 'Manual',
      doors: 4,
      price: 'R$60.000',
      images: [ onyx ,onyx2, onyx3, onyx4],
      description: "Onix Hatch Activ 1.4 8V Mea. 2018, R$60.000 á vista"
    },
    // Adicione mais objetos para representar outros carros
  ];

  const handleShowModal = (carData) => {
    setSelectedCar(carData); // Define os dados do carro selecionado
    setSelectedImage(carData.images[0]); // Define a primeira imagem do carro selecionado
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="container" style={{ marginTop: '1.5%', marginBottom: '3%' }}>
      <div className="row">
        {carsData.map((car, index) => (
          <div className="col-md-3" key={index} onClick={() => handleShowModal(car)}>
            <div className="card mb-3">
              <div className="card-body">
                <h5 className="card-title text-uppercase fw-bold">{car.name}</h5>
                <img src={car.images[0]} alt="Imagem do Carro" className="img-fluid mb-2" style={{ height: '250px', width: '300px'}} />
                <div className="row mb-2">
                  <div className="col text-center">
                    <p>KM: {car.km}</p>
                  </div>
                  <div className="col text-center">
                    <p>Ano: {car.year}</p>
                  </div>
                </div>
                <p className="mb-2">{car.description}</p>
              </div>
              <div className="text-center bg-dark text-white p-2">{car.price}</div>
            </div>
          </div>
        ))}
        {/* Renderize o modal */}
        <ModalContent
          show={showModal}
          handleCloseModal={handleCloseModal}
          selectedCar={selectedCar} // Passa os dados do carro selecionado como prop
        />
      </div>
    </div>
  );
}

export default CardGrid;
