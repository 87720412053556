import './styles.css'; 
import React from "react";
import { Header } from "../../components/header/index";
import Carousel from "../../components/carrousel/Index";
import { FilterComponent } from "../../components/filters/Index";
import 'bootstrap/dist/css/bootstrap.min.css';
import CardGrid from '../../components/cars/Index';
import ConsorcioComponent from '../../components/consorcio/Index';
import FooterComponent from '../../components/footer/Index';
export function HomeView () {
    return(

        <div className="body-inside">
            <Header/>
            <Carousel/>
            <FilterComponent/>
            <CardGrid/>
            <ConsorcioComponent/>
            <FooterComponent/>
        </div>

    )
}
