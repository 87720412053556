import './App.css';
import { Router } from './Routes';


function App() {
  return (
    <>
      <Router></Router>    
    </>
  );
}

export default App;
