import './styles.css'
import title from '../../assets/logo.png'
import consorcio from '../../assets/car 1.png'
import megafone from '../../assets/megaphone 1.png'
import zap from '../../assets/whatsapp.png';
import React, { useState } from 'react'


export function Header() {
  // Função para enviar mensagem para o número específico via WhatsApp
  const sendMessage = (phoneNumber, message) => {
    const formattedMessage = encodeURIComponent(message);
    window.open(`https://wa.me/${phoneNumber}?text=${formattedMessage}`, '_blank');
  };

  return (
    <div className="header-home">
      <img src={title} className='image-title' />
      <form className='input-header-home'>

      </form>
      <div className='actions-header'>
        {/* Seção Consórcios */}
        <div className='item-icon' onClick={() => sendMessage('5592981036965', 'Olá! Estou interessado em saber mais sobre as ofertas de consórcio e a compra de consórcios. Poderia me fornecer mais informações?')}>
          <img className='item-icon-individual' src={consorcio} />
          <span>
            CONSÓRCIOS
          </span>
        </div>
        {/* Seção Anuncie Aqui */}
        <div className='item-icon' onClick={() => sendMessage('5592981036965', 'Olá! Tenho interesse em anunciar meu carro na plataforma. Poderia me fornecer mais informações sobre como proceder?')}>
          <img className='item-icon-individual' src={megafone} />
          <span>ANUNCIE AQUI!</span>
        </div>
        <button className='btn' onClick={() => sendMessage('5592981036965', 'Olá! Estou entrando em contato através do site para obter mais informações.')}>
          Contatos
        </button>
      </div>
    </div>
  );
}
