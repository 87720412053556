import React from "react";
import './style.css'; 
import filter from '../../assets/Group.png'
import arrowDown from '../../assets/arrow-forward-ios 2.png'
import searchIcon from '../../assets/Vector.png'

export function FilterComponent () {
    return(
        <div className="container fields-container">
            <div className="row">
                {/* Lado Esquerdo */}
                <div className="col-md-6 d-flex align-items-center">
                    <button type="button" className="btn btn-dark me-5 teste"> {/* Aumentei a margem direita aqui */}
                        Filtros <img src={filter} alt="Ícone de filtro" className="ms-1" />
                    </button>
                    <button type="button" className="btn btn-dark me-5"> {/* Aumentei a margem direita aqui */}
                        Condição <img src={arrowDown} alt="Ícone de seta para baixo" className="ms-1" />
                    </button>
                    <button type="button" className="btn btn-dark">
                        Preço <img src={arrowDown} alt="Ícone de seta para baixo" className="ms-1" />
                    </button>
                </div>
                {/* Lado Direito */}
                <div className="col-md-6">
                    <div className="input-group">
                        <input type="text" className="form-control" placeholder="Pesquisar..." />
                        <span className="input-group-text bg-dark border-0">
                            <img src={searchIcon} alt="Ícone de busca" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}